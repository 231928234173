import React, { useState, useCallback, memo} from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { selectDateRange } from '../../features/analytics';

interface CategoryDateSelectorProps {
  onDateRangeRequest:(prevState: string[]) => void
}

const CategoryDateSelector: React.FC<CategoryDateSelectorProps> = memo(({onDateRangeRequest}) => {
  const { startTimeRange, endTimeRange } = useSelector(selectDateRange);
  const [startDate, setStartDate] = useState<Date | null>(new Date(`${startTimeRange}T12:00:00Z`));
  const [endDate, setEndDate] = useState<Date | null>(new Date(`${endTimeRange}T12:00:00Z`));

  const handleDateChange = (update: [Date | null, Date | null]) => {
      setStartDate(update[0]);
      setEndDate(update[1]);
  }

  const handleDatePickerClose = useCallback(() => {
    if(startDate && endDate) {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      onDateRangeRequest([formattedStartDate, formattedEndDate]);
    }
  }, [startDate, endDate]);

  const formatDate = (date: Date) => {
    const month = date?.getMonth() + 1;
    const year = date?.getFullYear();
    const day = date?.getDate();

  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

  };

  return (
      <DatePicker
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={handleDateChange}
        onCalendarClose={handleDatePickerClose}
      />
  );
});

export default CategoryDateSelector;
