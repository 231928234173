import React from 'react';
import { selectDateCounter } from '../../../features/analytics';
import { useSelector } from 'react-redux';
import Tile from '../../Common/Layout/Tile';
import { useGetContributionDetailsQuery } from '../../../features/api/apiSlice';

const currentYear = new Date().getFullYear();
const currentYearStart = new Date(currentYear, 0, 1);
const currentYearEnd = new Date(currentYear + 1, 0, 0);

const formatDate = (date: Date) => {
    return `${date.getMonth() + 1}/${date.getDate()}`;
};

const ActivityCalendar = () => {
    const eventsPerDay = useSelector(selectDateCounter);
    useGetContributionDetailsQuery();
    const gridItems = [];
    // Room for optimization, it creates a new date each loop
    for (let d = new Date(currentYearStart); d <= currentYearEnd; d.setDate(d.getDate() + 1)) {
        gridItems.push(new Date(d))
    }

    const getActivityColor = (date: Date) => {
        const colors = ['bg-gray-200 dark:bg-zinc-700', 'bg-primary-200', 'bg-primary-400', 'bg-primary-500', 'bg-primary-600'];
        const dateString = date.toISOString().split('T')[0];
        const tagCount = eventsPerDay[dateString] ?? 0;
        return colors[tagCount >= colors.length ? 4 : tagCount ]; 
    };

    const getTagCount = (date: Date) => {
        const dateString = date.toISOString().split('T')[0];
        return eventsPerDay[dateString] ?? 0;
    };

    return (
        <Tile title="Activity Calendar" height="h-[35vh]" heightMd="md:h-[35vh]" heightLg="lg:h-[40vh]">
            <div className=" cursor-pointer size-full overflow-y-scroll custom-upcomingTags-scrollbar" >
                <div className="flex flex-wrap ">
                    {gridItems.map((date) => (
                        <span 
                            key={`${date}`}
                            className={`w-3 h-3 md:w-4 md:h-4 lg:w-6 lg:h-6 sm-4 sm-4 ${getActivityColor(date)} m-0.5 rounded-sm`}
                            title={`${getTagCount(date)} events created on ${formatDate(date)}`}
                        />
                    ))}
                </div>
            </div>
        </Tile>
    );
}

export default React.memo(ActivityCalendar);