import React from 'react'
import { Fragment, } from 'react'
import { Dialog, Transition } from '@headlessui/react'

interface SynchronizeModalProps {
  isOpen: boolean
}
const SynchronizeModal = ({ isOpen }: SynchronizeModalProps) => {

  return (
    <> 
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={()=>{}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-8 sm:pt-0">
                    <div className="mt-1 text-center sm:mt-8">
                      <Dialog.Title as="h1" className="text-base text-lg font-semibold leading-6 text-gray-900">
                      </Dialog.Title>
                      <div className="h-44 flex justify-center items-center ">
                        <div className="flex justify-center items-center relative w-36 h-36 mb-4">
                            <div className="w-28 h-28 rounded-full absolute border-8 border-solid border-gray-200" style={{borderWidth: '12px'}}></div>
                            <div className="w-28 h-28 rounded-full animate-spin absolute border-8 border-solid border-red-500 border-t-transparent" style={{borderWidth: '12px'}}></div>
                            <h1 className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-medium text-3xl"><span className="text-lg">Syncing...</span></h1>
                        </div>
                      </div>
                    </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  </>

  )
}

export default SynchronizeModal;