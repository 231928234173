import React from 'react';
import {useRef} from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { Bar } from 'react-chartjs-2';
import { ChartBackgroundProp } from '../../../types/chart.interfaces'
import { selectTagDurationAndLabels } from '../../../features/analytics';
import { useBackgroundColor } from '../../../hooks/useBackgroundColor';
import { useMobile } from '../../../hooks/useMobile';
import { basicChartOptions } from '../../ChartOptions';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const CategoryBarChart: React.FC<ChartBackgroundProp> = ({ bgColorClass = "bg-primary-500" }) => {
  const {labels, categoryHours} = useSelector(selectTagDurationAndLabels);
  const colorBackgroundRef = useRef(null);
  const isMobile = useMobile();
  const currentColor = useBackgroundColor(colorBackgroundRef,bgColorClass)
  const options = basicChartOptions(isMobile);

  const data = {
  labels,
  datasets: [
    {
      data: categoryHours,
      backgroundColor: currentColor,
      borderRadius: 5,
    },

  ],
};
  return (          
        <>
          <div ref={colorBackgroundRef} className="bg-primary-500 hidden" ></div>
          <Bar options={options} data={data} style={{width:"100%", height:"100%"}}/>
        </>
  )
}


export default CategoryBarChart;
