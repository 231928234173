import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/stores';
import { Event } from '../types/chart.interfaces';

interface AnalyticsState {
    timeRange: string;
    isNewUser: boolean;
    selectedCategories: {[key:string]: boolean},
    startTimeRange: string;
    endTimeRange: string;
}

interface TagDurationAndNames {
    [key:string] : number
}
interface categoryHashMap {
    [key: string]: Event[]
}

// For setting the default customm date selector on dashboard. (1 week past, 1 week future)
  const getWeekStart = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - dayOfWeek);
    startOfWeek.setHours(0, 0, 0, 0); 
    return startOfWeek.toISOString().split('T')[0];
  };

  const getWeekEnd = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();

    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (6 - (dayOfWeek + 1)));
    endOfWeek.setHours(23, 59, 59, 999); 
    return endOfWeek.toISOString().split('T')[0];

  };


const initialState: AnalyticsState = {
    timeRange: 'week',
    isNewUser: false,
    selectedCategories: {},
    startTimeRange: getWeekStart(),
    endTimeRange: getWeekEnd(),
};

export const analyticSlice = createSlice({
    name:'analytics',
    initialState,
    reducers:{
        setUserStatus: (state, action) => {
            state.isNewUser = action.payload
        },
        setCategories: (state, action) => {
            const initialCategories: {[key:string]: boolean} = {};
            for (const data of action.payload) {
                if(data.category.id){
                    initialCategories[data.category.name] = true;
                }
            }
            state.selectedCategories = initialCategories;
        },
        updateEvent: (state, action) => {
            const { categoryName } = action.payload;
            state.selectedCategories[categoryName] = !state.selectedCategories[categoryName];
        },
        setTimeRange: (state, action) => {
            state.timeRange = action.payload
        },
        setDateRange: (state, action) => {
            state.startTimeRange = action.payload.startTimeRange;
            state.endTimeRange = action.payload.endTimeRange;
        }
    }
})

export const { setTimeRange, setDateRange, setUserStatus,setCategories, updateEvent } = analyticSlice.actions;

export const selectTimeRange = (state:RootState) => state.analytics.timeRange;
export const selectUserStatus = (state:RootState)=> state.analytics.isNewUser;
export const selectSelectedCategories = (state:RootState)=> state.analytics.selectedCategories;


export const selectDateRange = createSelector(
    [(state: RootState) => state.analytics.endTimeRange, state => state.analytics.startTimeRange],
    (endTimeRange, startTimeRange) => {
        return { endTimeRange, startTimeRange };
    }
);

// EVENT SELECTORS

// Grabs the events from the time filtered query
// Used to calcualte the total event analytic card/ Events per day chart, and Daily Event Activity Chart
export const selectTimeFilteredEvents = createSelector(
    [(state: RootState) => state, selectDateRange],
    (state, dateRange) => {
        const serializedQueryArgs = JSON.stringify(dateRange);
        const queryKey = `getDateRangeAnalytics(${serializedQueryArgs})`;
        const eventData = (state.api.queries[queryKey]?.data as {data: Event[]})?.data;
        return eventData || [];
    }
);
export const selectUniqueTimeFilteredEvents = createSelector(   
    [selectTimeFilteredEvents],
    (events) => {
        const unique: {[key: string]: Event} = {};
        events.forEach((cur)=>{
            if(unique[cur.event.name] === undefined) {
                unique[cur.event.name] = cur
            }
        })
        return Object.values(unique);
    }
);

export const selectCategories = createSelector(
    [(state: RootState) => state],
    (state) => {
        const queryKey = `getCategories(undefined)`;
        const categories = (state.api.queries[queryKey]?.data as {data: {id:number, name: string}[]})?.data 
        return categories || [];
    }
);
// Date Counter For Activity Calendar on dashbaord 
export const selectDateCounter = createSelector(
    [(state: RootState) => state], 
    (state) => {
        const queryKey = `getContributionDetails(undefined)`;
        const contributions = (state.api.queries[queryKey]?.data as {data: {count:string, date:string}[]})?.data
        const eventDurationAndNames: TagDurationAndNames = {};
        if (contributions && contributions.length > 0) {
            for (const current of contributions) {
                const eventDate = current.date.split('T')[0]
                eventDurationAndNames[eventDate] = Number(current.count)
        }
    }
        return eventDurationAndNames;
    }
);

const selectCategoryDurations = createSelector(
[selectTimeFilteredEvents],
    (events) =>{
        if(events.length === 0) return [];
        const categoryDurationsObject:{[key:string]: number} = events.reduce((acc:{[key:string]: number},cur)=>{
            if(cur.category.id) {
                acc[cur.category.name] = (acc[cur.category.name] || 0) + Number(cur.event.duration)
            }
            return acc;
        },{})

        const categoryDurationsArray = Object.keys(categoryDurationsObject).map((key)=>{
            return {categoryName: key, totalDuration: categoryDurationsObject[key]}
        })
        return categoryDurationsArray;
})

// Category Duration Overview Selector used in the dashboard
export const selectTagDurationAndLabels = createSelector(
[selectCategoryDurations, selectSelectedCategories],
    (categories, selectedCategories) =>{
        if(categories.length === 0) return {labels: [], categoryHours: []};
            const tagDurationAndNames:TagDurationAndNames = categories.reduce((acc:{[key:string]: number},cur)=> {
                if(selectedCategories[cur.categoryName]) {
                    acc[cur.categoryName] = cur.totalDuration
                }
                return acc
            },{})

        const tagDurationAndLabels = {labels: Object.keys(tagDurationAndNames), categoryHours: Object.values(tagDurationAndNames)}
        return tagDurationAndLabels;
    })

// For the analytic cards on the dashboard
export const selectCategoryStats = createSelector(
    [selectCategoryDurations, selectTimeFilteredEvents],
    (categoryDurations, events)=> {
        if(categoryDurations.length === 0) return {};
        const topCategory = categoryDurations.reduce((acc, current) => ( acc.totalDuration > current.totalDuration) ? acc : current )
        const totalCategoryCount = {count: categoryDurations.length, totalDuration: categoryDurations.reduce((acc, current) =>  acc + current.totalDuration, 0)}
        const bottomCategory = categoryDurations.reduce((acc, current) => ( acc.totalDuration < current.totalDuration) ? acc : current)
        const totalEventCount = events.length;
        return {topCategory, totalCategoryCount , bottomCategory,totalEventCount}
    }
);

// Organizer Selectors

// Display the categories and there tags in the organizer hub
export const selectUniqueCategoryTags = createSelector(
    [selectTimeFilteredEvents,selectCategories],
    (events, categories)=> {
        if(!events) return [];
        const categoryHashMap: categoryHashMap = {}
        const seenEvents:{[key:string]: boolean} = {}
        events.forEach((current) => {
            if (current.category.id && !seenEvents[current.event.name]) {
                if(categoryHashMap[current.category.name] ){
                    categoryHashMap[current.category.name].push(current)
                } else {
                    categoryHashMap[current.category.name] = [current]
                }
                seenEvents[current.event.name] = true
            }
        });

        const mapCategories: {[key:string]: {id: number, name: string}} = {};
        categories.forEach((category) => mapCategories[category.name] = category);
        // This adds any categories with zero items

        const categoryEvents = Object.keys(mapCategories).map((key)=>  ({category: mapCategories[key], events: categoryHashMap[key] ?? []}));

        return categoryEvents
    })

// Display unique events in the organizer hub word bank
export const selectUniqueWordBankTags = createSelector(
    [selectTimeFilteredEvents],
    (events) => {
        if (events.length === 0) return [];

        const seen:{[key:string]: Event} = events.reduce((acc:{[key:string]: Event},cur)=> {
            if(!acc[cur.event.name] && !cur.category.id){
                acc[cur.event.name] = cur;
            }
            return acc;
        }, {});
        events.forEach((cur) => {
            if (!seen[cur.event.name] && !cur.category.id) {
                seen[cur.event.name] = cur;
            }   
        })
        return Object.values(seen);
    }
);

// used in create store
export default analyticSlice.reducer;
