import { Fragment, useState, memo } from 'react'
import {Link, Outlet, useLocation} from 'react-router-dom'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import { useAuth0 } from "@auth0/auth0-react";
import ErrorBoundary from './ErrorBoundary';

import {
  ArrowRightOnRectangleIcon,
  Cog6ToothIcon,
  Bars3Icon,  
  HomeIcon,
  XMarkIcon,
  TagIcon,
  ChevronLeftIcon,
  RocketLaunchIcon
} from '@heroicons/react/24/outline'

import useTheme from '../hooks/useTheme';

const navigation = [
  { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: true },
]

const reportSubNavigation = [
  { name: 'Organizer Hub', href: '/organizer-hub', icon: TagIcon, current: false },
  { name: 'Goal Manager', href: '/calendar-goals', icon: RocketLaunchIcon, current: false },
]                       
const accountSubNavigation = [
  // { name: 'Help', href: 'https://calendar.google.com/calendar', icon: ChatBubbleOvalLeftIcon, current: false },
  { name: 'Settings', href: '/settings', icon: Cog6ToothIcon, current: false },
  { name: 'Log Out', href: '', icon: ArrowRightOnRectangleIcon, current: false },
]  

const userNavigation = [
  { name: 'Your Profile', href: '/settings' },
  { name: 'Sign Out', href: '' },
]

function classNames(...classes:string[]) {
  return classes.filter(Boolean).join(' ')
}

const SidePanel = memo(() => {
  const { user, logout } = useAuth0();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(JSON.parse(localStorage.getItem("isCollapsedCalentics") ?? 'false'));
  const { darkMode, setDarkMode} = useTheme();
  const location = useLocation();
  const returnToParam = import.meta.env.VITE_RETURNTO;

  const toggleSidebar = () => {
    localStorage.setItem("isCollapsedCalentics",`${!isCollapsed}`)
    setIsCollapsed(!isCollapsed);
  };

  return (
    <>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className={`relative z-50 lg:hidden ${darkMode ? 'dark' : 'light'}`}  onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-zinc-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex justify-end ">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-24 flex max-w-xs sm:max-w-sm md:max-w-md  flex-1" >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto px-6 pb-2 bg-zinc-600 dark:bg-tile">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=white"
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <div className="text-xs font-semibold leading-6 text-white dark:text-header">Overview</div>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                onClick={()=>setSidebarOpen(false)}
                                {...(item.name === 'Calendar' ? { target: '_blank' } : {})}
                                className={classNames(
                                  item.href === location.pathname
                                    ? 'bg-white text-zinc-500 '
                                    : 'text-white text-primary-500  hover:bg-white',
                                  'group flex gap-x-3 rounded-md p-2  text-sm leading-6 font-semibold dark:text-header'
                                )}
                              >
                                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-white dark:text-header">Report Generation</div>
                          <ul role="list" className="-mx-2 space-y-1">
                            {reportSubNavigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                onClick={()=>setSidebarOpen(false)}
                                {...(item.name === 'Calendar' ? { target: '#' } : {})}
                                className={classNames(
                                  item.href === location.pathname
                                    ? 'bg-white'
                                    : 'text-white text-primary-500 hover:bg-white ',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-header'
                                )}
                              >
                                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-white dark:text-header"> Account</div>
                          <ul role="list" className="-mx-2 space-y-1">
                              {accountSubNavigation.map((item) => (
                              <li key={item.name}>
                                {item.name !== 'Log Out' ? 
                                    <Link
                                      to={item.href}
                                      onClick={()=>setSidebarOpen(false)}
                                      {...(item.name === 'Calendar' ? { target: '#' } : {})}
                                      className={classNames(
                                        item.href === location.pathname
                                          ? 'bg-white text-zinc-500 '
                                          : 'text-white text-primary-500 hover:bg-white',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-header'
                                      )}
                                    >
                                      <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                      {item.name}
                                    </Link>
                                :
                                    <Link
                                      to={item.href}
                                      className={classNames(
                                        item.href === location.pathname
                                          ? 'bg-white text-zinc-500 '
                                          : 'text-white text-primary-500 hover:bg-white',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold dark:text-header'
                                      )}
                                      onClick={() => logout({ logoutParams: { returnTo:  returnToParam}})}
                                    >
                                      <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                      {item.name}
                              </Link> }
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <Switch
                            checked={darkMode}
                            onChange={() => setDarkMode(!darkMode)}
                            className={`bg-white  h-[14px] w-[42px]
                              relative inline-flex shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                          >
                            <span className="sr-only">Use setting</span>
                            <span
                              aria-hidden="true"
                              className={`${darkMode ? 'translate-x-7' : 'translate-x-0'} h-[10px] w-[10px]
                                pointer-events-none inline-block transform rounded-full shadow-lg bg-zinc-500 ring-0 transition duration-200 ease-in-out`}
                            />
                          </Switch>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
        {/* Static sidebar for desktop */}
        <div className={` hidden  lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col transition-width duration-500  ${isCollapsed ? 'w-16  overflow-hidden ' : 'w-72'} `} >
          <div className="flex grow overflow-x-hidden flex-col gap-y-5 overflow-y-auto bg-primary-500 dark:bg-tile  rounded-r-[10px]">
              {isCollapsed ?
              <div className="flex flex-col justify-center items-center h-16 shrink-0 border-b-2  dark:border-primary-500 border-white text-2xl cursor-pointer " onClick={toggleSidebar}>
                  <div className="border-2 rounded-sm px-2 pt-0 text-white dark:text-primary-500 border-white dark:border-primary-500">
                    C
                  </div>
              </div>

              : 
              <div className="flex h-16 shrink-0 items-center px-6 border-b-2 border-white dark:border-header ">
                <div className=" flex items-center justify-items-between text-2xl ">
                  <div className="border-2 rounded-sm px-2 pt-0 text-white border-white dark:text-header dark:border-header">
                    C
                  </div>
                  <div >
                    <div className="text-xl font-semibold leading-6 -mx-2 px-6 dark:text-header text-white">Calentics</div>
                    <div className="text-sm font-semibold leading-6 -mx-2 px-6  dark:text-header text-white whitespace-nowrap">Calendar Analytics</div>
                  </div>
                  <ChevronLeftIcon className=" md:ml-8 h-6 w-6 text-white dark:text-primary-500 cursor-pointer" onClick={toggleSidebar} />
                </div>
              </div>
              }

            <nav className="flex flex-1 flex-col">
              <ul  className={`flex flex-1 flex-col ${isCollapsed ? 'mt-4' : 'mt-6 gap-y-7'}`}>
                <li>
                    {isCollapsed ?
                      <>
                        <ul  className="flex flex-col justify-center items-center">
                          {navigation.map((item) => (
                          <li key={item.name} className="mt-1">
                            <Link
                              to={item.href}
                              {...(item.name === 'Calendar' ? { target: '#' } : {})}
                              className={classNames(
                                item.href === location.pathname
                                  ? 'text-primary-500 bg-white dark:text-primary-500 dark:bg-event-draggable'
                                  : ' text-white hover:bg-white hover:text-primary-500 dark:text-primary-500 dark:hover:bg-event-draggable',
                                'group flex gap-x-3 rounded-md p-2 text-md leading-6 '
                              )}
                            >
                              <item.icon className="h-8 w-8 shrink-0" aria-hidden="true" />
                            </Link>
                          </li>
                          ))}
                        </ul>
                      </>
                    : 
                    <> 
                      <div className="text-sm font-semibold leading-6 -mx-2 px-6 text-white dark:text-primary-500">Overview</div>
                      <ul  className="-mx-2 space-y-1 px-6 ">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                {...(item.name === 'Calendar' ? { target: '#' } : {})}
                                className={classNames(
                                  item.href === location.pathname
                                    ? 'text-primary-500 bg-white dark:bg-event-draggable dark:text-header '
                                    : ' text-white hover:bg-white hover:text-primary-500 dark:hover:bg-event-draggable dark:text-header',
                                  'group flex gap-x-3 rounded-md p-2 text-md leading-6 '
                                )}
                              >
                                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </>
                    }
                </li>
                <li>
                  {isCollapsed ?
                  <> 
                      <ul className="flex flex-col justify-center items-center">
                      {reportSubNavigation.map((item) => (
                        <li key={item.name} className="mt-1">
                          <Link
                            to={item.href}
                            {...(item.name === 'Calendar' ? { target: '#' } : {})}
                            className={classNames(
                              item.href === location.pathname
                                ? 'text-primary-500 bg-white dark:text-primary-500 dark:bg-event-draggable'
                                : ' text-white hover:bg-white hover:text-primary-500 dark:hover:bg-event-draggable dark:text-primary-500',
                              'group flex gap-x-3 rounded-md p-2 text-md leading-6 '
                            )}
                          >
                            <item.icon className="h-8 w-8 shrink-0" aria-hidden="true" />
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                  :
                  <> 
                    <div className="text-sm font-semibold leading-6 -mx-2 px-6 whitespace-nowrap text-white dark:text-primary-500">Report Generation</div>
                    <ul  className="-mx-2 space-y-1 px-6 ">
                      {reportSubNavigation.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            {...(item.name === 'Calendar' ? { target: '#' } : {})}
                            className={classNames(
                              item.href === location.pathname
                                ? 'text-primary-500 bg-white dark:bg-event-draggable dark:text-header'
                                : 'text-white hover:bg-white hover:text-primary-500 dark:hover:bg-event-draggable dark:text-header',
                              'group flex gap-x-3 rounded-md p-2 text-md leading-6 whitespace-nowrap'
                            )}
                          >
                            <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </>
                }
                </li>
                <li>
                  {isCollapsed ?
                    <> 
                      <ul className="flex flex-col justify-center items-center">
                        {accountSubNavigation.map((item) => (
                          <li key={item.name} className="mt-1">
                            { item.name !== 'Log Out' ?
                            <Link
                              to={item.href}
                              {...(item.name === 'Calendar' ? { target: '#' } : {})}
                              className={classNames(
                                item.href === location.pathname
                                  ? 'text-primary-500 bg-white dark:text-primary-500 dark:bg-event-draggable'
                                  : ' text-white hover:bg-white hover:text-primary-500 dark:text-primary-500 dark:hover:bg-event-draggable',
                                'group flex gap-x-3 rounded-md p-2 text-md leading-6 '
                              )}
                            >
                              <item.icon className="h-8 w-8 shrink-0" aria-hidden="true" />
                            </Link>
                            : 
                            <Link
                              to={item.href}
                              className=' text-white hover:bg-white hover:text-primary-500 dark:text-primary-500 dark:hover:bg-event-draggable group flex gap-x-3 rounded-md p-2 text-md leading-6 '
                              onClick={() => logout({ logoutParams: { returnTo:  returnToParam}})}
                            >
                              <item.icon className="h-8 w-8 shrink-0" aria-hidden="true" />
                            </Link> }
                          </li>
                        ))}
                      </ul>
                    </>
                  :
                    <> 
                      <div className="text-sm font-semibold leading-6 -mx-2 px-6 text-white dark:text-primary-500">Account</div>
                      <ul  className="-mx-2 space-y-1 px-6">
                        {accountSubNavigation.map((item) => (
                          <li key={item.name} >
                            { item.name !== 'Log Out' ?
                            <Link
                              to={item.href}
                              {...(item.name === 'Calendar' ? { target: '#' } : {})}
                              className={classNames(
                                item.href === location.pathname
                                  ? 'text-primary-500 bg-white dark:bg-event-draggable dark:text-header'
                                  : 'text-white hover:bg-white hover:text-primary-500 dark:hover:bg-event-draggable dark:text-header',
                                'group flex gap-x-3 rounded-md p-2 text-md leading-6 '
                              )}
                            >
                              <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                              {item.name}
                            </Link>
                            :                             
                            <Link
                              to={item.href}
                              className='text-white hover:bg-white hover:text-primary-500 dark:hover:bg-event-draggable dark:text-header
                              group flex gap-x-3 rounded-md p-2 text-md leading-6'
                              onClick={() => logout({ logoutParams: { returnTo:  returnToParam}})}
                            >
                              <item.icon className="h-8 w-8 shrink-0" aria-hidden="true" />
                              {item.name}
                            </Link> }
                          </li>
                        ))}
                      </ul>
                    </>
                  }
                </li>

              <li className="mt-auto ">
                <div className="text-xs font-semibold leading-6 -mx-2 px-4 text-gray-400 mb-4">  
                  <Switch
                    checked={darkMode}
                    onChange={() => setDarkMode(!darkMode)}
                    className={`bg-white dark:bg-primary-500 ${isCollapsed ? 'h-[14px] w-[42px]' : 'h-[18px] w-[48px]' }
                      relative inline-flex shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                  >
                    <span className="sr-only">Use setting</span>
                    <span
                      aria-hidden="true"
                      className={`${darkMode ? 'translate-x-7' : 'translate-x-0'} ${isCollapsed ? 'h-[10px] w-[10px]' : 'h-[14px] w-[14px]' }
                        pointer-events-none inline-block transform rounded-full shadow-lg  dark:bg-zinc-800 bg-primary-500 ring-0 transition duration-200 ease-in-out`}
                    />
                  </Switch>
                </div>
                <div className="flex items-center gap-x-4 lg:gap-x-6 border-t-2 border-white ">
                  {isCollapsed ? 
                    <div className="flex items-center w-full m-3 ">
                      <Link to="settings" className="flex"> 
                        <img
                          className=" sm:h-5 sm:w-5 md:h-10 md:w-10 rounded-full"
                          src={user?.picture}
                          alt=""
                        />
                      </Link>
                    </div>
                    :
                    <>
                      <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10 mb-10" aria-hidden="true" />
                      <div className="flex justify-between items-center w-full p-1.5 ">
                        <Link to="settings" className="flex"> 
                        <img
                          className=" sm:h-5 sm:w-5 md:h-10 md:w-10 rounded-full"
                          src={user?.picture}
                          alt=""
                        />
                        <span className="hidden lg:flex lg:items-center">
                          <span className="sr-only">Open user menu</span>
                          <span className="ml-4 text-md dark:text-header leading-6 text-white whitespace-nowrap font-medium max-w-[150px] overflow-hidden" aria-hidden="true">
                            {user?.email?.split('@')[0]}
                          </span>
                        </span>
                        
                        </Link>
                      </div>
                     </>
                    }
                  
                </div>
              </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:hidden  bg-tile">
          <div className="sticky top-0 z-40 flex justify-between h-16 shrink-0 items-center gap-x-4 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8 ">
                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5 ">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src={user?.picture}
                      alt=""
                    />

                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute left-0 z-10 mt-2.5 w-32 origin-top-right rounded-md py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none bg-tile ">
                      {
                        userNavigation.map((item) => {
                          if (item.name !== 'Sign out') {
                            return (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link
                                    to={item.href}
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-500 dark:hover:bg-zinc-700'
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            );
                          }
                            return (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <Link
                                    to={item.href}
                                    onClick={() => logout({ logoutParams: { returnTo:  returnToParam}})}
                                    className={classNames(
                                      active ? 'bg-gray-50' : '',
                                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                                    )}
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            );
                        })
                      }
                    </Menu.Items>
                  </Transition>
                </Menu>
              <button type="button" className="-m-2.5 p-2.5 text-grey-700 dark:text-header lg:hidden" onClick={() => setSidebarOpen(true)}>
                <Bars3Icon className="h-8 w-8" aria-hidden="true" />
                <span className="sr-only">Open sidebar</span>
              </button>
              </div>
          </div>

        <div className={`${isCollapsed ? 'lg:pl-16 ': 'lg:pl-72'} transition-width duration-500 `}>
          <main className="py-3 mt-3">
            <div className="px-2 sm:px-6 lg:px-6">
              <ErrorBoundary>
                <Outlet/>
              </ErrorBoundary>
            </div>
          </main>
        </div>
    </>
  )
});

export default SidePanel