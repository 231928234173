import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyGetUserStatusQuery } from '../features/api/apiSlice';
import { setUserStatus } from '../features/analytics';
import { useAuth0 } from '@auth0/auth0-react';
// Checks the local storage to see if isNew is set
// If not checks the database and expose the isLoading variable that's used to display page loading
export const useUserStatus = () => {
    const {isAuthenticated} = useAuth0();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true)
    const [trigger, { data: userStatus, isSuccess, }] = useLazyGetUserStatusQuery();

    useEffect(() => {
        if (isAuthenticated) {
            const isNewUser = localStorage.getItem('isNew');
            const booleanIsNewUser = (isNewUser === 'false') ? false : true;

            dispatch(setUserStatus(booleanIsNewUser));
            // fires when the is no isNew in localstorage
            const checkUserStatus = async () => {
                if (isNewUser === null) {
                    try {
                        await trigger().unwrap();
                    } catch (err) {
                        console.error("Error fetching user status", err);
                    }
                } 
            };
            checkUserStatus();
        } 
        setIsLoading(false)
    }, [trigger, isAuthenticated]);

    useEffect(() => {
        if (isSuccess) {
            dispatch(setUserStatus(userStatus?.data));
            localStorage.setItem('isNew',String(userStatus?.data));
        }
    }, [isSuccess, userStatus, dispatch]);
    return isLoading;
};
