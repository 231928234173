import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import {selectUserStatus, } from '../features/analytics';
import {
    TopStatistics, CategoryMainChart, EventPanel 
    ,EventUsageGraph, ActivityCalendar, NotificationModal, CategoryHistoricalChart, } 
  from '../components/Dashboard/index'
import Header from '../components/Common/Layout/Header';

const Dashboard = () => {
  const { user, isAuthenticated } = useAuth0();
  const userStatus = useSelector(selectUserStatus);
  return (
    <div className="grid gap-3 sm:gap-4 grid-cols-12 custom-bg">
      
      { user && userStatus && isAuthenticated && <NotificationModal /> }
      <div className="col-span-12 ">
        <Header page={'Dashboard'}/>
        <TopStatistics />
      </div>
      <div className="col-span-12 md:col-span-8 lg:col-span-8">
        <CategoryMainChart />
      </div>
      <div className="col-span-12 md:col-span-4 lg:col-span-4 ">
        <EventPanel />
      </div>
      <div className="col-span-12 md:col-span-8 lg:col-span-8">
        <ActivityCalendar />
      </div>
      <div className="col-span-12 md:col-span-4 lg:col-span-4 ">
        <EventUsageGraph />
      </div>
      <div className="col-span-12">
        <CategoryHistoricalChart />
      </div>
    </div>
  )
}

export default Dashboard;