
import SynchronizeBtn from '../../Dashboard/stats/SynchronizeBtn';
import DateSelector from '../../DateSelector';
import { useAuth0 } from '@auth0/auth0-react';
import { selectUserStatus } from '../../../features/analytics';
import { useSelector } from 'react-redux';

const Header = ({page}: {page: string}) => {
    const { user, isAuthenticated } = useAuth0();
    const isNewuser = useSelector(selectUserStatus);
    return (
        <div className="flex w-100 justify-between items-baseline flex-col sm:flex-row"> 
                <h1 className=" font-semibold text-3xl leading-6 text-gray-900 pt-1 dark:text-gray-400 ">{page}</h1>
                { user && isNewuser && isAuthenticated ? <SynchronizeBtn email={user?.email ?? ''} sub={user?.sub ?? ''} name={user?.given_name ?? ''} /> 
                : <DateSelector/> }
        </div>
      )
};

export default Header;