import React, { useState, useEffect, useContext, PropsWithChildren } from 'react';
import ThemeContext, { ThemeProps } from '../context/ThemeContext';

type ThemeProviders = PropsWithChildren<{}>;

const useManagedTheme = () => {
    const [themeContent, setThemeContent] = useState(localStorage.getItem('themeCalentics') || 'red');
    const [darkMode, setDarkMode] = useState(localStorage.getItem("darkModeCalentics") === 'true');

    useEffect(() => {
        localStorage.setItem('themeCalentics', themeContent);
        localStorage.setItem('darkModeCalentics', darkMode.toString());
        document.body.style.background = darkMode ? '#18181b' : 'rgb(225, 225, 225)';
       applyDatePickerStyles(); // Apply dark mode styles for date picker
    }, [themeContent, darkMode]);

    const applyDatePickerStyles = () => {
        const styleId = 'custom-datepicker-styles';
        let style = document.getElementById(styleId);

        // If a style tag with the specified ID already exists, remove it
        if (style && style?.parentNode) {
            style.parentNode.removeChild(style);
        }

        // Create a new style tag
        style = document.createElement('style');
        style.id = styleId; // Assign the unique ID to the style tag
        if (darkMode) {
            style.innerHTML = `
                .react-datepicker__input-container > input {
                    background-color: #27272a !important;
                    border: none;
                    color: #6b7280;
                }
                .react-datepicker {
                    border: none;
                    background-color: transparent;
                }
                .react-datepicker__month-container {
                    background-color: #27272a !important;
                    border-radius: 10px !important;
                }
                .react-datepicker__header {
                    background-color: #27272a;
                }
                .react-datepicker__current-month, .react-datepicker__day-name, .react-datepicker__day {
                    color: #6b7280 !important;
                }
                .react-datepicker__day--in-range {
                    background: hsl(var(--twc-primary-500));
                    color: white !important;
                }
            `;
        } else {
            style.innerHTML = `
            .react-datepicker {
                border: 1px solid #d1d1d1;
            }
            .react-datepicker__input-container>input:hover {
                cursor: pointer;
            }
            .react-datepicker__input-container>input:focus {
                outline: none !important;

            }
            .react-datepicker__header {
                background-color: white;
            }
            .react-datepicker__day-name {
                font-weight: bold;
            }
            .react-datepicker__day--in-range {
                background: hsl(var(--twc-primary-500));
            }
            .react-datepicker__day--in-range:hover{
                background: hsl(var(--twc-primary-400));
            }
            .react-datepicker__day--in-selecting-range:hover {
                background: hsl(var(--twc-primary-400));
            }
            .react-datepicker__day--keyboard-selected{
                color: white !important;
            }
            .react-datepicker__day--in-selecting-range {
                background: hsl(var(--twc-primary-400)) !important;
            }`;
        }

        document.head.appendChild(style);
    }
    return { themeContent, setThemeContent, darkMode, setDarkMode };
}

export const ThemeContextProvider: React.FC<ThemeProviders> = ({ children }) => {
    const theme = useManagedTheme();
    return (
        <ThemeContext.Provider value={theme}>
            {children}
        </ThemeContext.Provider>
    );
}

export const useTheme = (): ThemeProps => {
    const context = useContext(ThemeContext);

    if (context === undefined) {
        throw new Error('useTheme must be used within a ThemeProvider');
    }
    return context;
}

export default useTheme;
