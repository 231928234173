import React, { ReactNode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";


interface Auth0ProviderWithNavigateProps {
  children: ReactNode;
}

export const Auth0ProviderWithNavigate: React.FC<Auth0ProviderWithNavigateProps> = ({ children }) => {
  const navigate = useNavigate();

  // Auth0 configuration
  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const redirectUri = import.meta.env.VITE_AUTH0_CALLBACK_URL;
  const audience = import.meta.env.VITE_AUDIENCE

  const onRedirectCallback = (appState: any) => {
    navigate('/dashboard' || window.location.pathname);
  };


  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: audience,
        connection_scope: "https://www.googleapis.com/auth/calendar.readonly",
        connection: 'google-oauth2',
        access_type: 'offline',
        approval_prompt: 'force',
        scope:"openid email profile https://www.googleapis.com/auth/calendar.readonly",
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
        {children}
    </Auth0Provider>
  );
};
