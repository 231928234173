import {  PlusIcon, TrophyIcon, XCircleIcon, UsersIcon,} from '@heroicons/react/24/outline'
import { useSelector } from 'react-redux';
import { selectCategoryStats } from '../../../features/analytics';

const TopStatistics = () => {
  const {topCategory, totalCategoryCount,bottomCategory, totalEventCount } = useSelector(selectCategoryStats);

  // const {data: calendars} = useGetUserCalendarsQuery();
  const stats = [
    { id: 1, name: 'Total Events', value: totalEventCount || '0', stat: false,icon: UsersIcon,  },
    { id: 2, name: 'Top Category',value: topCategory?.categoryName || 'N/A', stat: topCategory?.totalDuration,icon: TrophyIcon},
    { id: 3, name: 'Bottom Category',value: bottomCategory?.categoryName || 'N/A', stat: bottomCategory?.totalDuration, icon: XCircleIcon},
    { id: 4, name: 'Category Count',value: totalCategoryCount?.count || 'N/A', stat: totalCategoryCount?.totalDuration,icon: PlusIcon },
  ]

  return (
    <div>
      <dl className="mt-4 grid grid-cols-2 gap-3 md:gap-5 lg:grid-cols-4">
      {stats.map((item) => (
          <div key={item.name} className="overflow-hidden rounded-md bg-tile shadow p-2 lg:p-3 shadow-custom hover:cursor-pointer dark:opacity-80">
            <div className="flex justify-between w-full"> 
                <div className="absolute rounded-md bg-primary-500 p-1 sm:p-2">
                  <item.icon className="h-6 w-6 xl:h-8 xl:w-8 text-white" aria-hidden="true" />
                </div>
                <p className="ml-11 sm:ml-16 truncate text-sm font-medium text-header ">{item.name}</p>
                {/* <dt className="truncate text-xs sm:text-sm font-medium text-gray-500">{item.stat ? item.stat + " hrs" : null}</dt> */}
            </div>
            <dd className="ml-12 sm:ml-16 flex items-baseline">
              <p className="text-sm sm:text-xl font-semibold text-zinc-800 dark:text-gray-400 ">{item.value}</p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

export default TopStatistics;


