
const isDarkMode = () => {
    const darkMode = localStorage.getItem('darkModeCalentics');
    return darkMode == 'true' ? '#6b7280' : undefined;
}
export const basicChartOptions = (isMobile: boolean) => ({
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
      }
    },
    y: {
      title: {
        display: !isMobile,
        text: 'Hours',
        font: {
          fontWeight: 700,
          size: 14,
        },
      },
    border:{dash: [8, 8]}, //grid lines
    grid: {
      color: isDarkMode(), // grid lines
      tickBorderDash: [0, 1], // Hiding the ticks
      offset: true,
      lineWidth: 1.5,
      },
    min: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
});