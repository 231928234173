import Tile from '../../Common/Layout/Tile';
import CategoryBarChart from './CategoryBarChart';

const CategoryMainChart = () => {
    return (
        <Tile title="Categories" >
            <CategoryBarChart/>
        </Tile>
    )

};

export default CategoryMainChart;