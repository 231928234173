// Cache updates are streamed via websockets
// Every endpoint needs to update the cache on there event
// These are the building blocks for creating a cache updating function

// Returns a function for you to pass in the updated data, the redux function updatedCacheData
// The arg which allows you to access global state
const addToCache = (base, updateCache) => {
    return ({data, accessCache, arg}) => {
        data.forEach((cur)=> base({cur, arg, accessCache, updateCache}))
    };
};

// Cache like the getDataAnalytic events is time dependent 
// So you need to make sure the incoming events fall within the current selected time range
const updateCacheWithinTimeRange = ({cur, arg, accessCache, updateCache}) => {
    const eventDate = cur.event !== undefined ? cur.event.startDate.split('T')[0] : cur.startDate.split('T')[0]; 
    if (arg.startTimeRange <= eventDate && eventDate <= arg.endTimeRange) {
        accessCache((draft) => updateCache(draft,cur));
    }
};

// Cache like getContribution or a delete events are time range independent
const updateCacheWithoutTimeRange = ({cur, arg, accessCache, updateCache}) => {
    accessCache((draft) => updateCache(draft,cur));
};

const getDateRangeAnalytics = {
    addCalendarEvent: addToCache(updateCacheWithinTimeRange, (draft, cur) => {draft.data.push(cur)}),
    updateCalendarEvent: addToCache(updateCacheWithinTimeRange, (draft, cur) => {
        const existingIndex = draft.data.findIndex(item => item.event.gcalId === cur.event.gcalId);
        existingIndex !== -1 ? draft.data[existingIndex] = cur : draft.data.push(cur);
    }),
    deleteCalendarEvent: addToCache(updateCacheWithinTimeRange, (draft, cur) => {
        draft.data = draft.data.filter((item)=> cur.gcalId !== item.event.gcalId)
    }),
};

const getGoals = {
    addCalendarEvent: addToCache(updateCacheWithoutTimeRange, (draft, cur) => {
        const existingIndex = draft.data.findIndex(current => cur.event.nameId === current.event.id);
        if (existingIndex !== -1) {
            if (draft.data[existingIndex].goal.type === 'occurence') {
                let {progress, amount} = draft.data[existingIndex].goal;
                progress = `${Number(progress) + 1}`
                if (progress == amount) {
                    draft.data[existingIndex].goal.status  = `complete`;
                }
                draft.data[existingIndex].goal.progress = progress;
            } else {
                draft.data[existingIndex].goal.progress = `${Number(draft.data[existingIndex].goal.progress) + Number(cur.event.duration)}`
            }
        }
    }),
    deleteCalendarEvent: addToCache(updateCacheWithoutTimeRange, (draft, cur) => {
        const existingIndex = draft.data.findIndex(current => cur.event.nameId === current.event.id);
        if (existingIndex !== -1) {
            if (draft.data[existingIndex].goal.type === 'occurence') {
                const progress = draft.data[existingIndex].goal.progress
                draft.data[existingIndex].goal.progress  = `${Number(draft.data[existingIndex].goal.progress) - 1}`
            } else {
                draft.data[existingIndex].goal.progress  = `${Number(draft.data[existingIndex].goal.progress) - Number(cur.event.duration)}`
            }
        }
    }),
};

const getContributionDetails = {
    addCalendarEvent: addToCache(updateCacheWithoutTimeRange, (draft, cur) => {
        const eventDate = cur.event.startDate.split('T')[0]
        const existingIndex = draft.data.findIndex(contribution => eventDate === contribution.date.split('T')[0]);
        existingIndex !== -1 ? draft.data[existingIndex].count  = `${Number(draft.data[existingIndex].count) + 1}` : draft.data.push({count: '1', date:cur.event.startDate});
    }),
    deleteCalendarEvent: addToCache(updateCacheWithoutTimeRange, (draft, cur) => {
        const eventDate = cur.startDate.split('T')[0]
        const existingIndex = draft.data.findIndex(contribution => eventDate === contribution.date.split('T')[0]);
        if (existingIndex !== -1) draft.data[existingIndex].count  = `${Number(draft.data[existingIndex].count) - 1}`;
    }),
};

export default {
    getDateRangeAnalytics,
    getGoals,
    getContributionDetails
}
