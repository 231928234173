import React, { useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import SidePanel from './components/SidePanel';
import { useGetDateRangeAnalyticsQuery } from './features/api/apiSlice';
import { selectDateRange, setCategories } from './features/analytics';

const MainLayout = () => {
    const dateRange = useSelector(selectDateRange);
    const {data } = useGetDateRangeAnalyticsQuery(dateRange);
    const dispatch = useDispatch();

    useEffect(()=>{
    // Prevents the filtered events from getting set to the cache on every page change
    // The only time events should be able to be overridden is if dateRange changes
        if(data) {
            dispatch(setCategories(data.data))
        }
    },[dateRange, data]);

  return <SidePanel/>
};

export default MainLayout;