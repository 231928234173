import React from 'react';
import {useState, useRef} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { ChartBackgroundProp } from '../../../types/chart.interfaces'
import { useBackgroundColor } from '../../../hooks/useBackgroundColor';
import { useLazyGetHistoricalEventsQuery } from '../../../features/api/apiSlice';
import CategoryDateSelector from '../CategoryDateSelector';
import { Loading } from '../../Common/Loaders/Loading';
import { useMobile } from '../../../hooks/useMobile';
import { useSelector } from 'react-redux';
import { selectDateRange } from '../../../features/analytics';
import Tile from '../../Common/Layout/Tile';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const DesktopRange = ({handleDateRangeRequest, colorBackgroundRef,setDateRange1,setDateRange2}) => {
  return ( 
      < >
        <div className="flex items-end"> 
          <span className="m-1 font-semibold text-header">Range 1:</span><CategoryDateSelector onDateRangeRequest={setDateRange1}/> 
          <span className="m-1 font-semibold text-header">Range 2:</span><CategoryDateSelector onDateRangeRequest={setDateRange2}/>
          <button onClick={handleDateRangeRequest} className=' m-1 bg-primary-500 text-white rounded-md p-1 font-medium mt-4 shadow-custom dark:opacity-80'>{`>`}</button>
        </div>
        <div ref={colorBackgroundRef} className="bg-primary-500 hidden" ></div>
      </>
  )
}

const MobileRange = ({setDateRange1,setDateRange2,colorBackgroundRef}) => {
    return (
      <div className="flex flex-col">
        <div className="flex justify-between "> 
          <div>
            <CategoryDateSelector onDateRangeRequest={setDateRange1}/>
          </div>
          <div>
            <CategoryDateSelector onDateRangeRequest={setDateRange2}/>
          </div>
        </div>
        <div ref={colorBackgroundRef} className="bg-primary-500 hidden" ></div>
      </div>
    )
};

const CategoryHistoricalChart: React.FC<ChartBackgroundProp> = ({ bgColorClass = "bg-primary-500" }) => {
  const {startTimeRange, endTimeRange} = useSelector(selectDateRange);
  const colorBackgroundRef = useRef(null);
  const isMobile = useMobile();
  const currentColor = useBackgroundColor(colorBackgroundRef,bgColorClass);
  const darkMode = localStorage.getItem('darkModeCalentics');
  const [dateRange1,setDateRange1] = useState<string[]>([startTimeRange, endTimeRange]);
  const [dateRange2, setDateRange2] = useState<string[]>([startTimeRange, endTimeRange]);
  const [dateRangeRequest, { data: range , isLoading }] = useLazyGetHistoricalEventsQuery();
  const handleDateRangeRequest = async () => {
      const queryParams = {
        rangeOneStart: dateRange1[0],
        rangeOneEnd: dateRange1[1],
        rangeTwoStart: dateRange2[0],
        rangeTwoEnd: dateRange2[1],
      }
      const queryString = new URLSearchParams(queryParams).toString();
      await dateRangeRequest(queryString).unwrap()
  };

 const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        drawOnChartArea: false,
      },
      stacked:true,
    },
    y: {
    title: {
      display: !isMobile,
      text: 'Hours',
      font: {
         fontWeight: 700,
         color: '#6b7280',
        size: 14,
      },
    },
    border:{dash: [8, 8]}, //grid lines
    grid: {
      color: darkMode == 'true' ? '#6b7280' : undefined, // grid lines
      tickBorderDash: [0, 1], // Hiding the ticks
      offset: true,
      lineWidth: 1.5,
      },
      stacked:true
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};
const data = {
  labels: range?.data?.labels,
  datasets: [
    {
      label: 'Date Range 1',
      data: range?.data?.rangeOne,
      backgroundColor: currentColor,
    },
    {
      label: 'Date Range 2',
      data: range?.data?.rangeTwo,
      backgroundColor: '#ededed' ,
    },
  ],
};
  return (          
    <Tile title={`Historical Comparison`} heightMd="md:h-[35vh]" heightLg="lg:h-[50vh]" AddOn={!isMobile && <DesktopRange setDateRange1={setDateRange1} setDateRange2={setDateRange2} colorBackgroundRef={colorBackgroundRef} handleDateRangeRequest={handleDateRangeRequest}/>}>
      {isMobile && <MobileRange setDateRange1={setDateRange1} setDateRange2={setDateRange2} colorBackgroundRef={colorBackgroundRef} />}
      {isLoading ? <Loading/> : <Bar options={options} data={data} style={{width:"100%", height:"100%"}}/> }
    </Tile>
  )
}


export default CategoryHistoricalChart;
