import React, {useState} from 'react'
import DatePicker from 'react-datepicker';
import { useDispatch, useSelector} from 'react-redux';
import { selectDateRange, setDateRange} from '../features/analytics';

const DateSelector = () => {
  const {startTimeRange, endTimeRange} = useSelector(selectDateRange);
  // Have to specify time due to iso string being passed in as it will default to midnight prev day
  const [startDate, setStartDate] = useState<Date | null>(new Date(`${startTimeRange}T12:00:00Z`));
  const [endDate, setEndDate] = useState<Date | null>(new Date(`${endTimeRange}T12:00:00Z`));
  const dispatch =  useDispatch();

  const handleDateChange = (update: [Date | null, Date | null]) =>{
      setStartDate(update[0]);
      setEndDate(update[1]);
  };

  const handleToday = () => {
    const today = new Date();
    setStartDate(new Date(`${today}`));
    setEndDate(new Date(`${today}`));
    handleDatePickerClose(today,today);
  }
  const getWeekStartAndEnd = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - dayOfWeek);
    startOfWeek.setHours(0, 0, 0, 0); 
    
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (6 - dayOfWeek));
    endOfWeek.setHours(23, 59, 59, 999); 

    return { startOfWeek, endOfWeek };
  };

  const handleWeek = () => {
    const { startOfWeek, endOfWeek } = getWeekStartAndEnd();
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
    handleDatePickerClose(startOfWeek, endOfWeek);
  };

  const getMonthStartAndEnd = () => {
  const today = new Date();
  
  // Calculate the start of the month
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  startOfMonth.setHours(0, 0, 0, 0); // Set to start of the day
  
  // Calculate the end of the month
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  endOfMonth.setHours(23, 59, 59, 999); // Set to end of the day

  return { startOfMonth, endOfMonth };
};

const handleMonth = () => {
  const { startOfMonth, endOfMonth } = getMonthStartAndEnd();
  setStartDate(startOfMonth);
  setEndDate(endOfMonth);
  handleDatePickerClose(startOfMonth, endOfMonth);
};

  const handleDatePickerClose = (startDate: Date,endDate: Date) => {
    if( startDate && endDate) {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);
      dispatch(setDateRange({startTimeRange: formattedStartDate, endTimeRange: formattedEndDate}))
    }
  };
  
const formatDate = (date: Date) => {
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const day = date.getDate();

  // Use String.prototype.padStart to ensure two digits
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

  return (
    <div className='flex justify-between items-center sm:justify-end w-full sm:w-auto'>
        <div>                
          <button
            type="button"
            className='inline-flex w-14 h-8 md:h-10 sm:w-20 justify-center items-center shadow-custom rounded-sm px-3 text-sm font-semibold  bg-white dark:bg-tile text-header mt-3 sm:mt-0 sm:mr-4'
            onClick={handleToday}
            >
              Today
          </button> 
        </div>
        <div>                
          <button
            type="button"
            className='inline-flex w-14 h-8 md:h-10 sm:w-20 justify-center items-center shadow-custom rounded-sm px-3  text-sm font-semibold  bg-white dark:bg-tile text-header mt-3 sm:mt-0 sm:mr-4'
            onClick={handleWeek}
            >
              Week
          </button> 
        </div>   
        <div>                
          <button
            type="button"
            className='inline-flex w-14 h-8 md:h-10 sm:w-20 justify-center items-center shadow-custom rounded-sm px-3  text-sm font-semibold  bg-white dark:bg-tile text-header mt-3 sm:mt-0 sm:mr-4'
            onClick={handleMonth}
            >
              Month
          </button> 
        </div>
        <div className="bg-white dark:bg-tile h-8 md:h-10 sm:p-1 shadow-custom items-center rounded-sm mt-3 sm:mt-0"> 
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateChange}
              onCalendarClose={() => handleDatePickerClose(startDate,endDate)}
            />
        </div>
      </div>

  );
};

export default DateSelector;