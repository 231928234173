import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { selectSelectedCategories, updateEvent } from '../../features/analytics';
import Tile from '../Common/Layout/Tile';

const EventPanel = () => {
  const filteredEvents = useSelector(selectSelectedCategories);
  const dispatch = useDispatch();

  const handleEventClick = (key: string) => {
      dispatch(updateEvent({ categoryName: key }))
  };

  return (
        <Tile title="Filter">
          <div className="flex justify-between border-b-4 border-primary-500 font-small text-header text-sm md:text-base"> 
            <p>Category</p>
          </div>
          {Object.keys(filteredEvents).map((key, idx) => (
            <div key={idx} className="flex justify-between p-1 hover:bg-zinc-200 dark:hover:bg-zinc-600  text-sm md:text-base" onClick={() => handleEventClick(key)}> 
              <label className="hover:cursor-pointer text-zinc-800 dark:text-gray-400" htmlFor={`tag-${idx}`}>{key}</label>
              <input 
                className={`${filteredEvents[key] ? 'text-primary-500' : null} focus:ring-0`}
                type="checkbox" 
                id={`tag-${idx}`} 
                name={`tag-${idx}`} 
                checked={filteredEvents[key]} 
                onClick={() => handleEventClick(key)}
                onChange={() => handleEventClick(key)}
              />
            </div>
          ))}
      </Tile>
  );
};


export default EventPanel;

