import { Dialog } from "@headlessui/react";
import BaseModal from "../../Common/BaseModal";
import { Link } from "react-router-dom";
import { useState } from "react";

const NotificationModal = () => {
    const [open, setOpen] = useState(true)
  return (
      <BaseModal open={open} setOpen={setOpen}>
        <div className="mt-1 text-center sm:mt-8">
          <Dialog.Title as="h1" className="text-base text-lg font-semibold leading-6 text-gray-900">
            🎉 Quick Start Roadmap 🎉
          </Dialog.Title>
          <div className="mt-2">
            <ul className="text-md text-left ">
              <li className="pb-2">1. Watch the brief demo video to get the most out of Calentics! <Link to="https://app.supademo.com/demo/clzdbe12l0qio9x77nhhmn23i" style={{color:'#0000EE'}}>(Demo)</Link></li>
              <li className="pb-2">2. Add events in Google Calendar for today. <Link to="https://calendar.google.com/" style={{color:'#0000EE'}}>(Learn How)</Link> </li>
              <li>3. Click 'Synchronize' in the top right corner to analyze these events. </li>
            </ul>
          </div>
        </div>
    </BaseModal>
  )
}

export default NotificationModal;