// @ts-nocheck
import { createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import { sec } from './security';
import { EventTypes } from '../../types/chart.interfaces'
import { Goal } from '../../types/chart.interfaces';
import sliceHelpers from '../../utils/apiSliceHelpers';
import apiSliceHelpers from '../../utils/apiSliceHelpers';
import {socket, initSocket} from '../../socket';

interface AnalyticEventData{
  data: EventTypes[];
}

interface AnalyticRangeParams {
  startTimeRange: string,
  endTimeRange: string,
}

interface CreateCategoryParams {
    categoryName: string;
    tags: { event_name: string; duration: string; }[]
}
interface CreateCategoryData {
  data: {
    id: string | null; 
    name: string | null;
  }[]
}

interface DeleteCategoryParams {
  category: {
    id: number;
    name: string;
  }
  dateRange: AnalyticRangeParams
}

interface CategoryOperationParams {
  category: {
    id: number;
    name: string;
  },
  event: {
    name: string;
    gcalId: string;
    startDate:string;
    duration: string;
    nameId: number
  }; 
}


interface CreateUserParams {
  email: string;
  sub: string; 
  name: string;
}
// Used to provide token to websocket on initial connection

let socket;
const baseUrl = import.meta.env.VITE_NODE_ENV === 'production' ? 'https://api.calentics.app/api/v1' : 'http://localhost:8080/api/v1'
const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      const access_token = await sec.getAccessTokenSilently()({
        authorizationParams: {
            audience: `https://www.example.com/qz4f2w`,
            },
        });
      if (access_token) {
        headers.set('Authorization', `Bearer ${access_token}`);
      }
      return headers;
    },
})

export const apiSlice = createApi({
    baseQuery,
    keepUnusedDataFor: 3600,
    tagTypes: ['CalendarEvents','Categories', 'Goals','Contributions'],
    endpoints: builder => ({
        getDateRangeAnalytics: builder.query<AnalyticEventData, AnalyticRangeParams>({
          query: (queryParams) => ({
              url: `/events`,
              params: queryParams,
            }),
            providesTags: (result, error, id) => [{ type: 'CalendarEvents',  id: "CalendarEventsDateRange" }],
      async onCacheEntryAdded(
        arg,
        { dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        try {
          // Waits for the initial query to resolve and caches the data before proceeding
          const result = await cacheDataLoaded;
          socket = initSocket(result)

          socket.on("Synchronized",(data)=> dispatch(apiSlice.util.invalidateTags(['CalendarEvents'])));
          socket.on("AddCalendarEvent", (data) => sliceHelpers.getDateRangeAnalytics.addCalendarEvent({data, accessCache: updateCachedData,arg}));
          socket.on("UpdateCalendarEvent", (data) => sliceHelpers.getDateRangeAnalytics.updateCalendarEvent({data, accessCache: updateCachedData,arg}));
          socket.on("DeletedCalendarEvent",(data) => sliceHelpers.getDateRangeAnalytics.deleteCalendarEvent({data, accessCache: updateCachedData,arg}));
        } catch (error) {
            dispatch(apiSlice.util.invalidateTags(['CalendarEvents']))
        } finally {
            await cacheEntryRemoved;
            if (socket) socket.disconnect();
        }
      },
        }),
        createUser: builder.mutation<void,CreateUserParams>({
          query: (userData) => ({
            method: 'POST',
            url: '/users',
            body: userData
          })
        }),
        createCategory: builder.mutation<CreateCategoryData,CreateCategoryParams>({
          query: (category) => ({
            url: `/categories`,
            method: 'POST',
            body: category,
          }),
          invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
          async onQueryStarted(category , { dispatch, queryFulfilled }) {
            const postResult = dispatch(
              apiSlice.util.updateQueryData('getCategories', undefined, (draft) => {
                draft.data.push({id: null, name: category.categoryName});
              })
            )
            try {
              await queryFulfilled
            } catch {
              postResult.undo()
            }
          },
        }),
          deleteCategory: builder.mutation<void, DeleteCategoryParams>({
            query: ({category}) => ({
              url: `/categories/${encodeURIComponent(category.id)}`,
              method: 'DELETE',
            }),
            invalidatesTags: [{ type: 'Goals'}],
            async onQueryStarted({category, dateRange}, { dispatch, queryFulfilled }) {
            const deleteCategoryResult = dispatch(
              apiSlice.util.updateQueryData('getCategories', undefined, (draft) => {
                draft.data = draft.data.filter((cur) => cur.id !== category.id);
              })
            )
            const postResult = dispatch(
              apiSlice.util.updateQueryData('getDateRangeAnalytics', dateRange, (draft) => {
                return {
                  data: draft.data.map((cur) => cur.category.id === category.id ? {...cur, category: {id: null, name: null}} : cur)
                }
              })
            )
            try {
              await queryFulfilled
            } catch {
              deleteCategoryResult.undo()
              postResult.undo()
            }
          },
        }),
        addTagToCategory: builder.mutation<string, CategoryOperationParams>({
          query: ({category, data}) => ({
            url: '/categories/event',
            method:'PATCH',
            body: {categoryId: category.id, nameId: data.event.nameId}
          }),
          invalidatesTags: [{ type: 'Goals'}],
          async onQueryStarted({category, data, dateRange}, { dispatch, queryFulfilled }) {
            const postResult = dispatch(
              apiSlice.util.updateQueryData('getDateRangeAnalytics', dateRange, (draft) => {
                  draft.data.forEach((cur) => {
                    if(cur.event.nameId === data.event.nameId){
                      cur.category.name = category.name
                      cur.category.id = category.id
                    }
                  });
              })
            )
            try {
              await queryFulfilled
            } catch {
              postResult.undo()
            }
          },
        }),
        getCategories: builder.query<CreateCategoryData, void>({
          query: () => '/categories',
          providesTags: (result, error, id) => [{ type: 'Categories',  id: "LIST" }],
        }),
        getUserStatus: builder.query<{data: boolean}, void>({
          query: () => '/users/new-user'
        }),
        getHistoricalEvents: builder.query({
          query: (params) => ({
            url: `/events/historical?${params}`
          })
        }),
        createGoal: builder.mutation<void, {name: string, type: string, amount: string, categoryId: string | null, eventId: string | null, startDate: string, endDate: string}>({
          query: (goal) =>({
            url: "/goals",
            method: "POST",
            body: goal
          }),
          invalidatesTags: [{ type: 'Goals'}]
        }),
        deleteGoal: builder.mutation<void,number>({
          query: (id) => ({
            url:`/goals/${id}`,
            method:'DELETE',
          }),
          async onQueryStarted(id , { dispatch, queryFulfilled }) {
            const postResult = dispatch(
              apiSlice.util.updateQueryData('getGoals', undefined, (draft) => {
                draft.data = draft.data.filter((cur)=> cur.goal.id !== id)
              })
            )
            try {
              await queryFulfilled
            } catch {
              postResult.undo()
            }
          },
        }),
        getGoals: builder.query<Goal, void>({
          query: () => '/goals',
          providesTags: (result, error, id) => [{ type: 'Goals'}],
          async onCacheEntryAdded(arg,{dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved }
        ) {
          try {
            const result = await cacheDataLoaded;
            socket = initSocket(result)
            socket.on("AddCalendarEvent",(data)=> {
                data.forEach((cur)=>{
                  updateCachedData((draft) => {
                    const existingIndex = draft.data.findIndex(current => current?.event && cur.event.nameId === current.event.id);
                    if (existingIndex !== -1) {
                      if (draft.data[existingIndex].goal.type === 'occurence') {
                          let {progress, amount} = draft.data[existingIndex].goal;
                          progress = `${Number(progress) + 1}`
                          if (progress == amount) {
                              draft.data[existingIndex].goal.status = `complete`;
                              dispatch(apiSlice.util.invalidateTags(['Goals']));
                          }
                          draft.data[existingIndex].goal.progress = progress;
                        }
                    }  else {
                        dispatch(apiSlice.util.invalidateTags(['Goals']));
                    }
                  })
                })
            });
            socket.on("UpdateCalendarEvent",(data)=> dispatch(apiSlice.util.invalidateTags(['Goals'])));
            socket.on("DeletedCalendarEvent", (data)=> dispatch(apiSlice.util.invalidateTags(['Goals'])));
          } catch (error) {
            dispatch(apiSlice.util.invalidateTags(['Goals']))
          } finally {
              await cacheEntryRemoved;
          }
        }
        }),
        deleteAccount: builder.mutation<void,void>({
          query:()=>({
            url: `/users`,
            method: 'DELETE',
          })
        }),
        getContributionDetails: builder.query<any,void>({
          query: () => '/events/contribution',
          providesTags: (result, error, id) => [{ type: 'Contributions'}],
          async onCacheEntryAdded(arg,{dispatch, updateCachedData, cacheDataLoaded, cacheEntryRemoved }
        ) {
          try {
            const result = await cacheDataLoaded;
            socket = initSocket(result)
            socket.on("Synchronized",(data)=> dispatch(apiSlice.util.invalidateTags(['Contributions'])));
            socket.on("AddCalendarEvent",(data)=> apiSliceHelpers.getContributionDetails.addCalendarEvent({data, accessCache: updateCachedData,arg}));
            socket.on("UpdateCalendarEvent",(data)=> dispatch(apiSlice.util.invalidateTags(['Contributions'])));
            socket.on("DeletedCalendarEvent",(data)=> apiSliceHelpers.getContributionDetails.deleteCalendarEvent({data, accessCache: updateCachedData,arg}));
          } catch (error) {
            dispatch(apiSlice.util.invalidateTags(['Contributions']))
          } finally {
              await cacheEntryRemoved;
          }
        }
    })
  })
});

export const { useGetDateRangeAnalyticsQuery, useLazyGetDateRangeAnalyticsQuery, 
                useCreateCategoryMutation, useDeleteCategoryMutation, 
                useAddTagToCategoryMutation,
                useCreateUserMutation, useGetUserStatusQuery,
                useLazyGetUserStatusQuery,useLazyGetHistoricalEventsQuery, 
                useGetCategoriesQuery, useCreateGoalMutation,
                useGetGoalsQuery, useDeleteGoalMutation,
                useDeleteAccountMutation, useGetContributionDetailsQuery } = apiSlice

