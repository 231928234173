import React from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}
class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can log the error to an error reporting service
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
        <main className="grid place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"  style={{ minHeight: 'calc(100vh - 36px)'}}>
            <div className="text-center">
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-primary-600 sm:text-5xl">
                Something went wrong
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-500">
                Please try reloading and if the issue persists contact support.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
                <a href="#" className="text-sm font-semibold text-gray-500">
                Contact support <span aria-hidden="true">&rarr;</span>
                </a>
            </div>
            </div>
        </main>
        </>      
    );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
