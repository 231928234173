import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SynchronizeModal from '../modals/SynchronizeModal';
import { useCreateUserMutation, useLazyGetDateRangeAnalyticsQuery } from '../../../features/api/apiSlice';
import { selectDateRange, setUserStatus } from '../../../features/analytics';
import SynchronizeErrorModal from '../modals/SynchronizeErrorModal';

type SynchronizeBtnProps = {
  email: string;
  sub: string;
  name: string;
}

const SynchronizeBtn: React.FC<SynchronizeBtnProps> = ({ email, sub, name }) => {
    const dateRange = useSelector(selectDateRange);
    const [isClicked,setIsClicked] = useState(false);
    const [isError, setIsError] = useState(false);
    const [createUserReqest] = useCreateUserMutation();
    const [trigger] = useLazyGetDateRangeAnalyticsQuery();
    const dispatch = useDispatch();

    const handleSynchronize = async () => {
      setIsClicked(true);
      try {
        await createUserReqest({email, sub, name }).unwrap();

        dispatch(setUserStatus(false))
        localStorage.setItem('isNew', 'false');
        setIsClicked(false)
        await trigger(dateRange).unwrap();
      } catch (err) {
        setIsError(true);
        setIsClicked(false); 
        dispatch(setUserStatus(true))
      }
    }
    return (
        <> 
            { !isClicked ?
              <button onClick={handleSynchronize} className="bg-primary-500 hover:bg-white hover:text-primary-500 mb-0 mt-0 text-white font-bold py-1 px-4 rounded">Synchonize</button>
              : <SynchronizeModal isOpen={isClicked} />
            }
            {isError && <SynchronizeErrorModal isOpen={isError} setIsError={setIsError} /> }
        </>
    )
}

export default SynchronizeBtn;