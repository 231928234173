import React, { useRef } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useTheme } from '../../hooks/useTheme';

const BaseModal = ({children,open, setOpen}) => {
  const colorBackgroundRef = useRef<HTMLDivElement>(null);
  const {darkMode} = useTheme();

  return (
    <> 
      <div ref={colorBackgroundRef} className="bg-primary-500 hidden" ></div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className={`relative z-50 ${darkMode ? 'dark' : 'light'}`} onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white dark:bg-zinc-700 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-8 sm:pt-0">
                    <button
                      onClick={() => setOpen(false)}
                      className="absolute top-0 right-0 mt-2 mr-4 border-none text-gray-400 hover:text-gray-500 focus:outline-none"
                      aria-label="Close"
                    >
                        <span className="sr-only ">Close</span>
                        <span className="font-bold" > 
                          X
                        </span>
                    </button>
                    {children}

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
  </>

  )
}

export default BaseModal;