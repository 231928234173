import io from 'socket.io-client';

let socket;

const initSocket = (result) => {
    if (!socket) {
        let authorizationToken: string | null = null;          
        if (result.meta && result.meta.request.headers.get('authorization')) {
            authorizationToken = result.meta.request.headers.get('authorization');
        }
        const URL: string = import.meta.env.VITE_NODE_ENV === 'production' ? 'https://api.calentics.app/' : 'http://localhost:8080';
        const socketSettings: {path: string, auth: object} | {auth:object}= import.meta.env.VITE_NODE_ENV === 'production' ? { path: "/socket.io", auth:{token: authorizationToken}} : { auth:{token: authorizationToken}};
        socket = io(URL, socketSettings)
    }

    return socket;
};


export { initSocket, socket };