import {createContext}from 'react'

export interface ThemeProps {
  themeContent: string;
  setThemeContent: (theme: string) => void;
  darkMode: boolean;
  setDarkMode: (isDarkMode: boolean) => void;
}

const ThemeContext = createContext<ThemeProps>({
    themeContent:'blue',
    setThemeContent: () => {},
    darkMode: false,
    setDarkMode: () => {},
})

export default ThemeContext;