import { ReactNode } from "react";

interface TileProps {
    children: ReactNode, 
    title: string, 
    AddOn?: ReactNode
    height?: string,
    heightMd?: string,
    heightLg?: string,
}
// Add ons are anything you want to generate on the right side of the tile aligned with the tile name
const Tile = ({ children, title, AddOn, height = 'h-[30vh]', heightMd = 'md:h-[40vh]', heightLg = 'lg:h-[50vh]'}: TileProps) =>{
    return (
        <div className={`flex flex-col ${height} ${heightMd} ${heightLg}`}>
            <div className="bg-tile rounded-lg px-2 pb-8 sm:pb-10 h-full shadow-custom dark:opacity-80" >
                <div className="flex justify-between items-center mt-2 ">
                    <div className="sm:flex-auto">
                        <div className="flex pb-1 items-center">
                            <div className="bg-primary-500 rounded-lg h-2 w-2"></div>
                            <h1 className=" text-sm md:text-base font-medium px-2 text-header ">{title}</h1>
                        </div>
                    </div>
                    {AddOn}
            </div>
                    {children}
            </div>
        </div>
    )
};

export default Tile;