import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useAuth0 } from '@auth0/auth0-react';

import PageLoading from "../Common/Loaders/PageLoading";
import { useUserStatus } from "../../hooks/useUserStatus";
import { sec } from '../../features/api/security';
interface AuthenticationGuardProps {
  component: React.ComponentType
}

export const AuthenticationGuard:React.FC<AuthenticationGuardProps> = ({ component }) => {
    const { getAccessTokenSilently } = useAuth0();
    // Sets getAccessTokenSilently as a property on an object so rtk can use it to protect the requests
    sec.setAccessTokenSilently(getAccessTokenSilently);

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <PageLoading/>
    ),
  });
    const isLoading = useUserStatus();
    if(isLoading) {
      return <PageLoading/> 
    }


  return  <Component />;
};