import React,{useEffect, useState} from 'react'

// Grabs the color of hidden divs with bg-primary applied, then computes there css style
export const useBackgroundColor = (colorBackgroundRef:React.RefObject<HTMLDivElement>, bgColorClass:string) => {
    const [currentColor, setCurrentColor] = useState('');

    useEffect(() => {
        const getBackground = () => {
            const element = colorBackgroundRef.current;
            if(element !== null ){
            const computedColor = getComputedStyle(element).getPropertyValue('background-color');
            setCurrentColor(computedColor);
            }
        }
        getBackground();
        }, [bgColorClass]);

    return currentColor;
}
