import { useState } from 'react';
import { Dialog } from '@headlessui/react'
import WizardSadIcon from '../../../assets/errorWizard.png';
import BaseModal from '../../Common/BaseModal';
interface SynchronizeModalProps {
  isOpen: boolean;
  setIsError: (isError: boolean) => void;
}
const SynchronizeErrorModal = ({ isOpen, setIsError }: SynchronizeModalProps) => {
  const [open, setOpen] = useState(true)
  return (
    <BaseModal open={open} setOpen={setOpen}>
      <div className="mt-1 flex flex-col items-center sm:mt-4">
        <img src={WizardSadIcon} className="w-48" alt='Sad Wizard'/>
        <Dialog.Title as="h1" className="text-base text-lg font-semibold text-gray-900">
          We're sorry an error occured
        </Dialog.Title>
        <p>Please retry syncing</p>
      </div>
    </BaseModal>

  )
}

export default SynchronizeErrorModal;