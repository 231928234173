import "./sentrySetup";
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
// Error logging and performance monitoring init
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';

import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import MainLayout from './App';
import store from './app/stores';
import { ThemeContextProvider } from './hooks/useTheme';
import { Auth0ProviderWithNavigate } from './context/Auth0ProviderWithNavigate';
import { AuthenticationGuard } from './components/Auth/AuthenticationGuard';

import Dashboard from './pages/Dashboard';
import PageLoading from './components/Common/Loaders/PageLoading';
import useTheme from './hooks/useTheme';

import 'react-datepicker/dist/react-datepicker.css';
import './styles/styles.css';
import './index.css';

const Settings = lazy(() => import('./pages/Settings'));
const SettingsRouter = lazy(() => import('./components/Setting/SettingsRouter'));
const Organizer = lazy(() => import('./pages/Organizer'));
const Goal = lazy(() => import('./pages/Goal'));
const NoMatch = lazy(() => import('./components/NoMatch'));

Chart.register(CategoryScale);

const AuthenticationWithTheme = () => {
  const { themeContent, darkMode } = useTheme();

  return (
    <div className={`${themeContent} ${darkMode ? 'dark' : 'light'}`}>
      <AuthenticationGuard component={MainLayout} />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Auth0ProviderWithNavigate>
        <Provider store={store}>
          <ThemeContextProvider>
            <AuthenticationWithTheme />
          </ThemeContextProvider>
        </Provider>
      </Auth0ProviderWithNavigate>
    ),
    children: [
      { path: '/dashboard', element: <Dashboard /> },
      {
        path: "settings/*",
        element: (
          <Suspense fallback={<PageLoading />}>
            <Settings />
          </Suspense>
        ),
        children: [
          {
            path: "*",
            element: (
              <Suspense fallback={<PageLoading />}>
                <SettingsRouter />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "organizer-hub/*",
        element: (
          <Suspense fallback={<PageLoading />}>
            <DndProvider backend={HTML5Backend}>
              <Organizer />
            </DndProvider>
          </Suspense>
        )
      },
      { path: 'calendar-goals', element: (
          <Suspense fallback={<PageLoading />}>
            <Goal />
          </Suspense>
        )
      },
      { path: '*', element: ( 
        <Suspense fallback={<PageLoading />}>
              <NoMatch />
        </Suspense>
        )
      }
    ]
  }
]);

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
