import React from 'react';
import { useRef, useState, useEffect } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useSelector } from 'react-redux';
import { selectTimeFilteredEvents} from '../../../features/analytics';
import { useBackgroundColor } from '../../../hooks/useBackgroundColor';
import { useMobile } from '../../../hooks/useMobile';
import { basicChartOptions } from '../../ChartOptions';
import Tile from '../../Common/Layout/Tile';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday','Sunday'];

interface UsageOverTimeChartLineGraphProps {
  bgColorClass?:string;
}
const EventUsageGraph:React.FC<UsageOverTimeChartLineGraphProps> = ({ bgColorClass="bg-primary-500" } ) => {
  const colorBackgroundRef = useRef<HTMLDivElement>(null);
  const currentColor = useBackgroundColor(colorBackgroundRef,bgColorClass);
  const initialData = useSelector(selectTimeFilteredEvents);
  const [eventsPerDay, setEventsPerDay] = useState<number[]>([]);
  const isMobile = useMobile();
  const options = basicChartOptions(isMobile);

  useEffect(() => {
    if( initialData && initialData.length > 0) {
      const tagCountPerDay:{[key:string]: number} = {
        '1': 0,
        '2': 0,
        '3': 0,
        '4': 0,
        '5': 0,
        '6': 0,
        '7': 0,
      };
      for (const data of initialData) {
        const eventDate = new Date(data.event.startDate)
        const dayOfWeek = eventDate.getDay()
        tagCountPerDay[dayOfWeek] = ( tagCountPerDay[dayOfWeek] || 0) + 1;
      }
      setEventsPerDay(Object.values(tagCountPerDay));
    }else {
      setEventsPerDay([]);
    }
  }, [initialData]);
  
  const data = {
    labels,
    datasets: [
      {
        data: eventsPerDay,
        borderColor: currentColor,
        backgroundColor: currentColor,
        pointRadius: 5
      },
    ],
  };
  return (
      <Tile title="Events Per Day" {...(!isMobile && { height: "h-[35vh]", heightMd: "md:h-[35vh]", heightLg: "lg:h-[40vh]" })}>
        <div ref={colorBackgroundRef} className="bg-primary-500 hidden"></div>
        <Line options={options} data={data} />
      </Tile>
  )
}


export default EventUsageGraph;
